import React, { useEffect, useState} from "react";
import { getDashboardInventoryMetrics } from "../api/dashboardAPI";

const Dashboard = () => {
    const [metrics, setMetrics] = useState(null);
    const [displayInventoryMetrics, setDisplayInventoryMetrics] = useState(true);

    useEffect(() => {
        //code om specifiek de metrics op te halen voor inventory
        const fetchInventoryMetrics = async () => {
            try{
                const response = await getDashboardInventoryMetrics();
                setMetrics(response.data)
            } catch (error) {
                console.error('Error fetching inventory metrics:', error);
            }
        };

        fetchInventoryMetrics();
    }, []);

    const handleInventoryButtonCLick = () => {
        setDisplayInventoryMetrics(true);
    };

    const handleSalesButtonClick = () => {
        setDisplayInventoryMetrics(false);
    }

    return (
            <div>
                <h2>Dashboard Metrics</h2>
                <button onClick={handleInventoryButtonCLick}>Inventory</button>
                <button onClick={handleSalesButtonClick}>Sales</button>
                {displayInventoryMetrics ? (
                    <div>
                        <table>
                            <thead>
                                <tr>
                                    <th>Product Name</th>
                                    <th>Stock Level</th>
                                </tr>
                            </thead>
                            <tbody>
                                {metrics?.inventory.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.ProductName}</td>
                                        <td>{item.Stocklevel}</td>
                                    </tr>
                                ))}
                                <tr>
                                    <td>Total</td>
                                    <td>{metrics?.inventoryCount}</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>Total Inventory: {metrics?.inventoryCount}</p>
                        <p>Low Stock Count: {metrics?.lowStockCount}</p>
                    </div>
                ) : (
                    <div>
                        <p>Total Sales: {metrics?.totalSales}</p>
                        <p>Total Revenue: {metrics?.totalRevenue}</p>
                    </div>
                )}
            </div>
    );
};

export default Dashboard;
