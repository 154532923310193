import React, { useEffect, useState } from "react";
import { getNewOrders, confirmOrder, cancelOrder, updateActualDeliveryDate, submitSale } from "../../../api/salesAPI";
import Datepicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import nl from 'date-fns/locale/nl';
import {format} from 'date-fns';
import './orders.css';

const OpenOrders = () => {
    const [orders, setOrders] = useState([]);
    const [isFetchingOrders, setIsFetchingOrders] = useState(false);
    const [isUpdatingDeliveryDate, setIsUpdatingDeliveryDate] = useState(false);
    const [confirmingOrder, setConfirmingOrder] = useState(false);
    const [isSendingOrder, setIsSendingOrder] = useState(false);
    const [isCancelingOrder, setIsCancelingOrder] = useState(false);

    useEffect(() => {
        const fetchOrders = async () => {
            setIsFetchingOrders(true);
            try {
                const response = await getNewOrders();
                setOrders(response.data)
            } catch (error) {
                console.error("Error fetching orders:", error);
            } finally {
                setIsFetchingOrders(false);
            };
        };

        fetchOrders();
    }, []);

    const handleButtonConfirmOrder = async (orderID) => {
        setConfirmingOrder(prev => ({ ...prev, [orderID]: true }));
        try {
            await confirmOrder(orderID);
            // refresh orders after confirmation
            const response = await getNewOrders();
            setOrders(response.data);
        } catch (error) {
            console.error("Error confirming order:", error);
        } finally {
            setConfirmingOrder(prev => ({ ...prev, [orderID]: false }));
        };
    };

    const handleButtonCancelOrder = async (orderID) => {
        setIsCancelingOrder(prev => ({ ...prev, [orderID]: true }));
        try {
            await cancelOrder(orderID);
            // refresh orders after confirmation
            const response = await getNewOrders();
            setOrders(response.data);
        } catch (error) {
            console.error("Error confirming order:", error);
        } finally {
            setIsCancelingOrder(prev => ({ ...prev, [orderID]: false }));
        };
    };

    const handleActualDeliveryDateChange = async (orderID, date) => {
        setIsUpdatingDeliveryDate(prev => ({...prev, [orderID]: true}));
        try {
            //Format the date to dd-mm-yyyy
            const formattedDate = format(date, 'yyyy-MM-dd');
            await updateActualDeliveryDate(orderID, {ActualDeliveryDate: formattedDate});

            //Refresh orders adter updating actual delivery date
            const response = await getNewOrders();
            setOrders(response.data);
        } catch (error) {
            console.error('Error setting actual delivery date:', error);
        } finally {
            setIsUpdatingDeliveryDate(prev => ({...prev, [orderID]: false}));
        };
    };

    const handleSendOrder = async (orderID) => {
        setIsSendingOrder(prev => ({...prev, [orderID]: true}));
        try {
            // Call the API to submit the sale and set the orderstatus to verzonden
            await submitSale({OrderID: orderID});

            // Refresh orders after submitting the sale
            const response = await getNewOrders();
            setOrders(response.data);
        } catch (error) {
            console.error('Error sending order:', error);
        } finally {
            setIsSendingOrder(prev => ({...prev, [orderID]: false}));
        };
    };

    const renderProductDetail = (detail) => (
        <div key={detail.ProductID} style={{padding: '5px 0'}}>
            <div><strong>{detail.ProductName}</strong></div>
            <div>{detail.SaleQuantity} cilinder(s)</div>
        </div>
    )

    return (
        <div>
            <h2>Orders</h2>
            {isFetchingOrders ? (
                <div className="spinner"></div>
                ) : (
            <table>
                <thead>
                    <tr>
                        <th>Klant</th>
                        <th>Besteldatum</th>
                        <th>Gewenste leverdatum</th>
                        <th>Werkelijke leverdatum</th>
                        <th>Ordernummer</th>
                        <th>Ordernummer klant</th>
                        <th>Orderstatus</th>
                        <th>Product en hoeveelheid</th>
                        <th>Opmerking</th>
                        <th>Adres</th>
                    </tr>
                </thead>
                <tbody>
                    {orders?.map((order) => (
                        <tr key={order.OrderID}>
                            <td className="bordered-cell">{order.CustomerName}</td>
                            <td className="bordered-cell">{order.OrderDate ? new Date(order.OrderDate).toLocaleDateString('nl-NL'): ''}</td>
                            <td className="bordered-cell">{order.PreferredDeliveryDate ? new Date(order.PreferredDeliveryDate).toLocaleDateString('nl-NL'): ''}</td>
                            <td className="bordered-cell">
                                {order.ActualDeliveryDate ? (
                                    new Date(order.ActualDeliveryDate).toLocaleDateString('nl-NL')
                                ) : (
                                    isUpdatingDeliveryDate[order.OrderID] ? (
                                        <div className="spinner"></div> // Show spinner when updating
                                    ) : (
                                        <Datepicker
                                            selected={order.ActualDeliveryDate ? new Date(order.ActualDeliveryDate) : null}
                                            onChange={(date) => handleActualDeliveryDateChange(order.OrderID, date)}
                                            minDate={new Date()} // prevent selection of past dates
                                            filterDate={(date) => {
                                                const today = new Date();
                                                return (date.getDay() !== 0 && date.getDay() !== 6)
                                            }}
                                            placeholderText="Selecteer een datum"
                                            locale={nl}
                                            dateFormat="dd/MM/yyyy"
                                        />
                                    )
                                )}
                            </td>
                            <td className="bordered-cell">{order.InvoiceNumber}</td>
                            <td className="bordered-cell">{order.CustomerInvoice}</td>
                            <td className="bordered-cell">{order.OrderStatus}</td>
                            <td className="bordered-cell">
                                {order.OrderDetails?.map(renderProductDetail)}
                            </td>
                            <td className="bordered-cell">{order.Comment}</td>
                            <td className="bordered-cell">{order.DeliveryCity} {order.DeliveryZipCode} {order.DeliveryStreetName} {order.DeliveryHouseNumber}</td>
                            {/* <td>
                                <button>
                                    Order aanpassen
                                </button>
                            </td> */}
                            <td>
                                {order.OrderStatus === 'nieuw' && order.ActualDeliveryDate && (
                                    confirmingOrder[order.OrderID] ? (
                                        <button disabled>
                                            <div className="spinner"></div> Bevestigen...
                                        </button>
                                    ) : (
                                        <button onClick={() => handleButtonConfirmOrder(order.OrderID)}>
                                            Bevestig order
                                        </button>
                                    )
                                )}
                            </td>
                            <td>
                                {order.OrderStatus === 'bevestigd' && order.ActualDeliveryDate && (
                                    isSendingOrder[order.OrderID] ? (
                                        <button disabled>
                                            <div className="spinner"></div> Verzenden...
                                        </button>
                                    ) : (
                                        <button onClick={() => handleSendOrder(order.OrderID)}>
                                            Verzend order
                                        </button>
                                    )
                                )}
                            </td>
                            <td>
                                {isCancelingOrder[order.OrderID] ? (
                                    <button disabled>
                                        <div className="spinner"></div> Annuleren...
                                    </button>
                                ) : (
                                    <button onClick={() => handleButtonCancelOrder(order.OrderID)}>
                                        Annuleer order
                                    </button>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        )}
        </div>
    );
};

export default OpenOrders;