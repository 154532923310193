import React from 'react';

const OrderModal = ({ 
    isOpen,
    onClose,
    order,
    displayData,
    onSubmitOrder,
    isSubmitting
    }) => {
    if (!isOpen) return null;

    const modalStyle = {
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        padding: '20px',
        zIndex: 1050, // Ensure modal is above overlay
        border: '1px solid #ccc', // Adding a border
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Shadow effect for modal
        width: '50%', // Adjust width as necessary
        maxHeight: '80vh', // Optional: Limits the height
        overflowY: 'auto' // Optional: Adds scroll to the modal if content is too long
    };

    const overlayStyle = {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black background
        zIndex: 1040 // Below the modal but above everything else
    };

    const renderOrderDetails = () => {
        if (!order) return <p>No order details available.</p>;

        return (
            <div>
                <h3>Order overzicht</h3>
                <ul>
                    <li><strong>Klantnaam:</strong> {order?.CustomerName}</li>
                    <li><strong>Ordernummer portaal:</strong> {order?.InvoiceNumber}</li>
                    <li><strong>Inkoopordernummer klant:</strong> {order?.CustomerInvoice || 'Niet ingevuld'}</li>
                    <li><strong>Opmerking:</strong> {order?.Comments || 'Niet ingevuld'}</li>
                    <li><strong>Gewenste leverdatum:</strong> {order?.PreferredDeliveryDate}</li>
                    <li><strong>Bezorgadres:</strong> {displayData?.addressDetails}</li>
                    <li><strong>Producten:</strong>
                        <ul>
                            {displayData.productsDetails.map((product, index) => (
                                <li key={index}>{product.productName}: {product.quantity} cilinder(s)</li>
                            ))}
                        </ul>
                    </li>
                </ul>
            </div>
        );
    };

    return (
        <>
            <div style={overlayStyle} onClick={onClose} />
            <div style={modalStyle}>
                {renderOrderDetails()}
                <button onClick={onClose}>Annuleren</button>
                <button onClick={() => onSubmitOrder(order)} disabled={isSubmitting}>
                    {isSubmitting ? (
                        <div>
                            <div className="spinner"></div> Order invoeren...
                        </div>
                    ) : "Order invoeren"}
                </button>
            </div>
        </>
    );
};

export default OrderModal;
