import React, { useState, useEffect } from "react";
import { getCustomerMetrics, getPricingAgreementsForCustomer } from "../../../../api/crmAPI";
import { submitOrder, getLastInvoice } from "../../../../api/salesAPI";
import DatePicker from "react-datepicker";
import OrderModal from "./orderModal";
import "react-datepicker/dist/react-datepicker.css";

const InsertOrder = () => {
    const [customers, setCustomers] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState('');
    const [customerSearchInput, setCustomerSearchInput] = useState('');
    const [selectedCustomerData, setSelectedCustomerData] = useState(null);
    const [selectedCustomerPricingAgreements, setSelectedCustomerPricingAgreements] = useState([]);
    const [productQuantities, setProductQuantities] = useState({});
    const [selectedDeliveryDate, setSelectedDeliveryDate] = useState("");
    const [customerCurrentPage, setCustomerCurrentPage] = useState(1);
    const [selectedAddressID, setSelectedAddressID] = useState("");
    const [orderComments, setOrderComments] = useState("");
    const [customerInvoice, setCustomerInvoice] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [orderDetails, setOrderDetails] = useState(null);
    const [displayData, setDisplayData] = useState({ addressDetails: '', productsDetails: [] });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isFetchingCustomers, setIsFetchingCustomers] = useState(false);
    const [isSelectingCustomer, setIsSelectingCustomer] = useState(null);
    const customersPerPage = 10;

    useEffect(() => {
        refreshCustomers();
    }, []);

    const refreshCustomers = () => {
        setIsFetchingCustomers(true);
        getCustomerMetrics()
            .then(response => {
                setCustomers(response.data.customers);
            })
            .catch(error => {
                console.error('Error fetching customers:', error);
            })
            .finally(() => {
                setIsFetchingCustomers(false);
            });
    };

    const handleCustomerSelect = async (customerName) => {
        setIsSelectingCustomer(customerName);
        setSelectedCustomer(customerName);
        try {
            const response = await getPricingAgreementsForCustomer(customerName);
            setSelectedCustomerData(response.data);
            setSelectedCustomerPricingAgreements(response.data.PricingAgreements.filter(pa => pa.Price === 1));
            if (response.data.DeliveryAddresses.length === 1) {
                setSelectedAddressID(response.data.DeliveryAddresses[0].AddressID);
            }
            console.log(response.data);
        } catch (error) {
            console.error('Error fetching pricing agreements:', error);
            setSelectedCustomerData(null);
            setSelectedCustomerPricingAgreements([]);
        } finally {
            setIsSelectingCustomer(null);
        }
    };

    useEffect(() => {
        setCustomerCurrentPage(1); // Reset to first page when search input changes for a customer
    }, [customerSearchInput]);

    // Pagination and search logic for customer list
    const filteredCustomers = customers.filter((customer) =>
        customer.CustomerName.toLowerCase().includes(customerSearchInput.toLowerCase())
    );

    const totalCustomerPages = Math.ceil(filteredCustomers.length / customersPerPage);
    const indexOfLastCustomer = customerCurrentPage * customersPerPage;
    const indexOfFirstCustomer = indexOfLastCustomer - customersPerPage
    const currentCustomers = filteredCustomers.slice(indexOfFirstCustomer, indexOfLastCustomer);

    const goToNextCustomerPage = () => setCustomerCurrentPage((page) => Math.min(page + 1, totalCustomerPages));
    const goToPreviousCustomerPage = () => setCustomerCurrentPage((page) => Math.max(page -1, 1));

    const handleProductQuantityChange = (productID, quantity) => {
        setProductQuantities((prevQuantities) => ({
            ...prevQuantities,
            [productID]: quantity
        }));
    };

    const handleDeliveryDateChange = (date) => {
        setSelectedDeliveryDate(date);
    };

    const handleSubmitOrder = async () => {
        if (!selectedCustomerData) {
            alert('Selecteer een klant');
            return;
        }

        if (Object.keys(productQuantities).length === 0 || Object.values(productQuantities).every(qty => qty <0)) {
            alert('Voeg minstens één product toe');
            return;
        }

        if (!selectedDeliveryDate) {
            alert('Selecteer een leverdatum');
            return;
        }

        // Check if customer invoice is mandatory but not provided
        if (!customerInvoice && selectedCustomerData.CustomerInvoiceMandatory === 1) {
            const confirmProceed = window.confirm("Voor deze klant is een inkoopordernummer verplicht. Weet je zeker dat je de order door wilt voeren zonder inkoopordernummer?");
            if (!confirmProceed) {
                return; // Stop submission if user does not confirm
        }
    }

        if (!selectedAddressID) {
            alert('Selecteer een bezorgadres');
            return;
        }

        const productsWithQuantities = Object.entries(productQuantities).map(([productID, quantity]) => ({
            ProductID: productID,
            SaleQuantity: quantity,
            UnitPrice: 1
        }));

        // Get the selected delivery date
        const formattedDeliveryDate = selectedDeliveryDate.toLocaleDateString('nl-NL');
        const formattedDeliveryDateParts = formattedDeliveryDate.split('-');
        // Get the selected delivery date in the right format for the SQL injection
        const formattedDeliveryDateSQL = `${formattedDeliveryDateParts[2]}-${formattedDeliveryDateParts[1]}-${formattedDeliveryDateParts[0]}`

        // count from the last invoicenumber in the database
        let lastInvoiceNumber = "";
        try {
            const response = await getLastInvoice();
            lastInvoiceNumber = response.data.lastInvoice;
        } catch (error) {
            console.error("Error fetching last invoice number:", error);
        }

        // Calculate the next invoice number
        const numericPart = parseInt(lastInvoiceNumber.slice(4));
        const defaultInvoiceNumber = `LHP-${(numericPart + 1).toString().padStart(6, '0')}`;

        const order = {
            CustomerName: selectedCustomerData.CustomerName,
            InvoiceNumber: defaultInvoiceNumber,
            CustomerInvoice: customerInvoice,
            TransportationCosts: 0,
            SafetyCosts: 0,
            Comments: orderComments,
            products: productsWithQuantities,
            SelectedDeliveryAddress: selectedAddressID,
            PreferredDeliveryDate: formattedDeliveryDate,
            PreferredDeliveryDateSQL: formattedDeliveryDateSQL,
        };

        const addressDetails = selectedCustomerData.DeliveryAddresses.find(addr => addr.AddressID === Number(selectedAddressID));
        const readableAddress = addressDetails ? `${addressDetails.StreetName} ${addressDetails.HouseNumber}, ${addressDetails.ZipCode} ${addressDetails.City}` : 'Address not found';

        const productsWithDetails = Object.entries(productQuantities).map(([productID, quantity]) => {
            const productDetail = selectedCustomerPricingAgreements.find(prod => prod.ProductID === Number(productID));
            return {
                productName: productDetail ? productDetail.ProductName : 'Unknown Product',
                quantity,
            };
        });
    
        setDisplayData({
            addressDetails: readableAddress,
            productsDetails: productsWithDetails,
        });

        setOrderDetails(order);
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
        // reset the form
        setSelectedCustomer('');
        setSelectedCustomerData(null);
        setSelectedCustomerPricingAgreements([]);
        setProductQuantities({});
        setSelectedDeliveryDate(''); // Reset to current date or another appropriate default value
        setSelectedAddressID('');
        setOrderComments('');
        setCustomerInvoice('');
        setOrderDetails(null);
    };

    const handleOrderSubmission = async (order) => {
        setIsSubmitting(true);

        try {
            await submitOrder(order);
            closeModal();
        } catch (error) {
            console.error('Error submitting order:', error);
        } finally {
            setIsSubmitting(false);
        }
    }
    

    return (
        <div>
        <h1>Handmatige order invoeren</h1>
        {isFetchingCustomers ? (
            <div>
                <div className="spinner"></div> Laden...
            </div>
        ) : (
            <div>
                <input
                    type="text"
                    placeholder="Zoek een klant..."
                    value={customerSearchInput}
                    onChange={(e) => setCustomerSearchInput(e.target.value)}
                />
                <table>
                    <thead>
                        <tr>
                            <th>Klantnaam</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentCustomers.map((customer, index) => (
                            <tr key={index}>
                                <td>{customer.CustomerName}</td>
                                <td>
                                    {isSelectingCustomer === customer.CustomerName ? (
                                        <button disabled>
                                            <div>
                                                <div className="spinner"></div>Selecteren...
                                            </div>
                                        </button>
                                    ) : (
                                        <button onClick={() => handleCustomerSelect(customer.CustomerName)}>
                                            Selecteer
                                        </button>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div>
                    <button onClick={goToPreviousCustomerPage}>Vorige</button>
                    <button onClick={goToNextCustomerPage}>Volgende</button>
                </div>
                {selectedCustomerData && (
                    <div>
                        <h3>Geselecteerde klant: {selectedCustomerData.CustomerName}</h3>
                        {selectedCustomerPricingAgreements.length > 0 && (
                            <table>
                                <thead>
                                    <tr>
                                        <th>Product</th>
                                        <th>Hoeveelheid</th>
                                        <th>Gewenste leverdatum</th>
                                        <th>Inkoopordernummer klant</th>
                                        <th>Opmerking</th>
                                        <th>Bezorgadres</th>
                                        <th>Emailadres</th>
                                        <th>Actie</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedCustomerPricingAgreements?.map((agreement, index) => (
                                        <tr key={agreement.ProductID}>
                                            <td>{agreement.ProductName}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    min="0"
                                                    value={productQuantities[agreement.ProductID]}
                                                    onChange={(e) => handleProductQuantityChange(agreement.ProductID, e.target.value)}
                                                />
                                            </td>
                                            <td>
                                                {index === 0 && 
                                                <DatePicker
                                                    selected={selectedDeliveryDate}
                                                    onChange={handleDeliveryDateChange}
                                                    dateFormat="dd-MM-yyyy"
                                                />}
                                            </td>
                                            <td>
                                                {index === 0 &&
                                                <input 
                                                type="text"
                                                value={customerInvoice}
                                                onChange={(e) => setCustomerInvoice(e.target.value)}
                                                />
                                                }
                                            </td>
                                            <td>
                                                {index === 0 &&
                                                <input
                                                    type="text"
                                                    value={orderComments}
                                                    onChange={(e) => setOrderComments(e.target.value)}
                                                />
                                                }
                                            </td>
                                            <td>
                                                {index === 0  && (selectedCustomerData.DeliveryAddresses.length > 1 ? (
                                                    <select
                                                        value={selectedAddressID}
                                                        onChange={(e) => setSelectedAddressID(e.target.value)}
                                                    >
                                                        <option value="">Selecteer een bezorgadres...</option>
                                                        {selectedCustomerData.DeliveryAddresses.map((address, idx) => (
                                                            <option key={idx} value={address.AddressID}>
                                                                {address.StreetName} {address.HouseNumber}, {address.ZipCode} {address.City}
                                                            </option>
                                                        ))}
                                                    </select>
                                                ) : (
                                                    <p>{selectedCustomerData.DeliveryAddresses[0].StreetName} {selectedCustomerData.DeliveryAddresses[0].HouseNumber}, {selectedCustomerData.DeliveryAddresses[0].ZipCode} {selectedCustomerData.DeliveryAddresses[0].City}</p>
                                                ))}
                                            </td>
                                            <td>
                                                {index === 0 &&
                                                <p>{selectedCustomerData.EmailAddress}</p>
                                                }
                                            </td>
                                            <td>
                                                {index === 0 &&
                                                <button onClick={handleSubmitOrder}>Plaats order</button>
                                                }
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}
                    </div>

                )}
                <OrderModal 
                    isOpen={showModal}
                    onClose={closeModal}
                    order={orderDetails}
                    displayData={displayData}
                    onSubmitOrder={handleOrderSubmission}
                    isSubmitting={isSubmitting}
                />
            </div>
        )}
        </div>
    );
}

export default InsertOrder;