import React, {useEffect, useState} from "react";
import { getInventoryMutations } from "../../api/inventoryManagementAPI";

const AdjustmentsOverview = () => {
    const [metrics, setMetrics] = useState(null);
    const [displayAdjustments, setDisplayAdjustments] = useState(true);

    useEffect(() => {
        const fetchInventoryMutations = async () => {
            try {
                const response = await getInventoryMutations();
                setMetrics(response.data);
            } catch (error) {
                console.error('Error fetching inventory mutations: ', error);
            }
        };

        fetchInventoryMutations();
    }, []);

    const formatDate = (dateString) => {
        const options = {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'};
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    return (
        <div>
            <h2>Voorraadwijzigingen</h2>
            {displayAdjustments ? (
                <div>
                    <table>
                        <thead>
                            <tr>
                                <th>Wijzigingtype</th>
                                <th>Product</th>
                                <th>Hoeveelheid</th>
                                <th>Datum</th>
                                <th>Omschrijving</th>
                            </tr>
                        </thead>
                        <tbody>
                            {metrics?.map((adjustment, index) => (
                                <tr key={index}>
                                    <td>{adjustment.AdjustmentType}</td>
                                    <td>{adjustment.ProductName}</td>
                                    <td>{adjustment.AdjustmentQuantity}</td>
                                    <td>{formatDate(adjustment.AdjustmentDate)}</td>
                                    <td>{adjustment.Description}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <p>No adjustments to display.</p>
            )}
        </div>
    );
};

export default AdjustmentsOverview;