import axios from "axios";
import apiConfig from './apiConfig.json';
import axiosRetry from 'axios-retry';

const API_BASE_URL = apiConfig.API_BASE_URL;
const API_BASE_URL_AWS = apiConfig.API_BASE_URL_AWS;
const baseURL = process.env.REACT_APP_API_BASE_URL || apiConfig.API_BASE_URL;

const apiService = axios.create({
    baseURL: API_BASE_URL,
    auth: {
        username: 'LHapi',
        password: 'LH4545!API'
    }
});
const apiServiceAWS = axios.create({
    baseURL: baseURL,
    auth: {
        username: 'LHapi',
        password: 'LH4545!API'
    }
});

// Add retry logic to apiService for GET requests only
axiosRetry(apiService, {
    retries: 3,
    retryCondition: (error) => {
        // Retry only for GET requests and on network errors or non-200 status codes
        return (
            error.config.method === 'get' && // Check if the method is GET
            (axiosRetry.isNetworkOrIdempotentRequestError(error) || 
            (error.response && error.response.status !== 200))
        );
    },
    retryDelay: () => 1000,
    onRetry: (retryCount, error, requestConfig) => {
        console.log(`Retry attempt #${retryCount} for GET request ${requestConfig.url}`);
        if (error.response) {
            console.log(`Status code: ${error.response.status}`);
        } else {
            console.log(`Error message: ${error.message}`);
        }
    }
});

// Add retry logic to apiServiceAWS for GET requests only
axiosRetry(apiServiceAWS, {
    retries: 3,
    retryCondition: (error) => {
        // Retry only for GET requests and on network errors or non-200 status codes
        return (
            error.config.method === 'get' && // Check if the method is GET
            (axiosRetry.isNetworkOrIdempotentRequestError(error) || 
            (error.response && error.response.status !== 200))
        );
    },
    retryDelay: () => 1000,
    onRetry: (retryCount, error, requestConfig) => {
        console.log(`Retry attempt #${retryCount} for GET request to ${requestConfig.url}`);
        if (error.response) {
            console.log(`Status Code: ${error.response.status}`);
        } else {
            console.log(`Error message: ${error.message}`);
        }
    }
});


export {apiService, apiServiceAWS};