import React, {useState} from "react";
import { addCustomer, addDeliveryAddress, getCustomerMetrics } from "../../api/crmAPI";
import "../components.css";

const AddCustomerForm = ({ refreshCustomers }) => {
    const [customerName, setCustomerName] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [streetName, setStreetName] = useState("");
    const [houseNumber, setHouseNumber] = useState("");
    const [city, setCity] = useState("");
    const [addition, setAddition] = useState("");
    const [addingCustomer, setAddingCustomer] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check if all required fields are filled
        if (!customerName || !emailAddress || !city || !zipCode || !streetName || !houseNumber) {
            alert("Alle velden behalve telefoonnummer en toevoeging zijn verplicht");
            return;
        }

        setAddingCustomer(true);

        try {
            const customerResponse = await addCustomer({
                CustomerName: customerName,
                EmailAddress: emailAddress,
                PhoneNumber: phoneNumber
            });

            if (customerResponse.data.success) {
                alert("Klant toegevoegd en welkomstmail verstuurd");
            }

            if (customerResponse.data.message === "Customer added successfully") {
                await addDeliveryAddress({
                    CustomerName: customerName,
                    City: city,
                    ZipCode: zipCode,
                    StreetName: streetName,
                    HouseNumber: houseNumber,
                    Addition: addition
                });
            }

            //Clear form fields
            setCustomerName("");
            setEmailAddress("");
            setZipCode("");
            setStreetName("");
            setHouseNumber("");
            setAddition("");
            setCity("");
            setPhoneNumber("");
            refreshCustomers();
        } catch (error) {
            console.error("Error adding customer:", error);
        } finally {
            setAddingCustomer(false);
        }
    };

    return (
        <div>
        <h2>Klant toevoegen</h2>
        <form onSubmit={handleSubmit}>
            <div>
                <label>
                    Klantnaam:
                    <input
                        type="text"
                        value={customerName}
                        onChange={(e) => setCustomerName(e.target.value)}
                        maxLength={255}
                        />
                </label>
            </div>
            <div>
                <p>Bezorgadres:</p>
                <div className="address-container">
                    <div>
                        <label>
                            Straatnaam:
                            <input 
                                type="text"
                                value={streetName}
                                onChange={(e) => setStreetName(e.target.value)} 
                                maxLength={255}
                                />
                        </label>
                        <label>
                            Huisnummer:
                            <input 
                                type="text"
                                value={houseNumber}
                                onChange={(e) => setHouseNumber(e.target.value)} 
                                style={{ width: '40px' }} 
                                maxLength={10}
                                />
                        </label>
                    </div>
                    <div>
                        <label>
                            Postcode:
                            <input 
                                type="text" 
                                value={zipCode} 
                                onChange={(e) => setZipCode(e.target.value)} 
                                maxLength={10}
                                />
                        </label>
                        <label>
                            Stad:
                            <input 
                                type="text"
                                value={city}
                                onChange={(e) => setCity(e.target.value)} 
                                maxLength={255}
                            />
                        </label>
                    </div>
                </div>
            </div>
            <div>
                <p>Contactgegevens:</p>
                <label>
                    Telefoonnummer:
                    <input 
                        type="number"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        maxLength={10}
                        />
                </label>
            </div>
            <div>
                <label>
                    Emailadres:
                    <input 
                        type="text"
                        value={emailAddress}
                        onChange={(e) => setEmailAddress(e.target.value)}
                        maxLength={255}
                        />
                </label>
            </div>
            <button type="submit" disabled={addingCustomer}>
                {addingCustomer ? (
                    <div>
                        <div className="spinner"></div> Klant toevoegen...
                    </div>
                ) : "Klant toevoegen"}
            </button>
        </form>
    </div>
    )
    };

export default AddCustomerForm;