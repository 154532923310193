import React from "react";

const Home = () => {
    return (
        <div>
            <h1>Lastechniek Holland portaalbeheer</h1>
            <p>Welkom bij het portaalbeheer van het Lastechniek Holland bestelportaal. Hieronder vindt u een uitleg van de mogelijkheden in deze applicatie.</p>
            <p>De knoppen links worden hieronder uitgelegd</p>
            <h2>Producten</h2>
            <h3>Productenlijst</h3>
            <p>Onder productenlijst staan alle mogelijke producten die in het bestelportaal zouden kunnen worden weergegeven</p>
            <p>Hier kunnen ook producten worden toegevoegd of aangepast</p>
            <h2>Klanten</h2>
            <h3>Klantinfo</h3>
            <h4>Klant toevoegen</h4>
            <p>Onder klant toevoegen kunnen klanten worden toegevoegd. Alle velden behalve telefoonnummer zijn verplicht</p>
            <p>Zodra een klant wordt toegevoegd krijgt deze klant een email met een gebruikersnaam en wachtwoord</p>
            <p>De gebruikersnaam is gelijk aan de klantnaam en het wachtwoord is automatisch gegenereerd</p>
            <h4>Klantinfo</h4>
            <p>Onder klantinfo kunnen klanten worden opgezocht op klantnaam</p>
            <p>De klantgegevens kunnen hier worden ingezien en aangepast</p>
            <p>Ook het Portaalmanagement per klant wordt hier weergegeven</p>
            <p>Hier kunnen de producten die de klant in het portaal te zien krijgt worden aangevinkt</p>
            <p>Als een product is aangevinkt kan de klant het product in het bestelportaal zien, als het product niet is aangevinkt is het niet zichtbaar</p>
            <p>Daarnaast staat een vink met Inkoopordernummer invullen verplicht</p>
            <p>Als deze box is aangevinkt kan de klant de bestelling pas plaatsen als ze het inkoopordernummer hebben ingevuld. Als deze niet is aangevinkt is dit niet verplicht</p>
            <h2>Orders</h2>
            <h3>Openstaande orders</h3>
            <p>Hier staan alle openstaande orders van dit moment. Alle gegevens over de order zijn hier terug te vinden</p>
            <p>Hier kan een leverdatum aan de orders worden toegevoegd en de status van orders kan worden aangepast</p>
            <h3>Orders zoeken</h3>
            <p>Hier kunnen orders worden opgezocht. Alle orders kunnen hier worden opgezocht ook als deze al verzonden zijn.</p>

        </div>
    );
};

export default Home;