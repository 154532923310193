import{ apiService, apiServiceAWS } from './apiService';

export const getInventoryManagementProductsAndStockMetrics = () => {
    return apiServiceAWS.get('/inventorymanagement/productsandstock');
}

export const getStockQuantity = (productId) => {
    return apiServiceAWS.get(`/inventorymanagement/getstockquantity/${productId}`);
}

export const InventoryManagementUpdateProductFields = (productId, updates) => {
    return apiServiceAWS.patch(`/inventorymanagement/updateproduct/${productId}`, updates);
}

export const InventoryManagementNewProduct = (productData) => {
    return apiServiceAWS.post('/inventorymanagement/addproduct', productData);
}

export const InventoryManagementDeleteProduct = (productId) => {
    return apiServiceAWS.delete(`/inventorymanagement/deleteproduct/${productId}`);
}

// not in use
export const getInventoryMutations = () => {
    return apiService.get('/inventorymanagement/inventoryadjustments');
}