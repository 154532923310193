import React, { useEffect, useState} from "react";
import { addSale } from "../../api/salesAPI";
import { getCustomerMetrics, getPricingAgreementsForCustomer } from "../../api/crmAPI";

const SalesMutations = () => {
    const [customers, setCustomers] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState('');
    const [selectedCustomerData, setSelectedCustomerData] = useState(null);
    const [selectedCustomerPricingAgreements, setSelectedCustomerPricingAgreements] = useState([]);
    const [saleDate, setSaleDate] = useState('');
    const [saleQuantity, setSaleQuantity] = useState('');
    const [invoiceNumber, setInvoiceNumber] = useState('');
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [sales, setSales] = useState([]);
    const [selectedProductPricing, setSelectedProductPricing] = useState(null);

    useEffect(() => {
        // Fetch the list of customers when the component mounts
        getCustomerMetrics()
            .then(response => {
                setCustomers(response.data.customers);
            })
            .catch(error => {
                console.error('Error fetching customers:', error);
            });
    }, []);

    const handleCustomerSelect = async (customerName) => {
        setSelectedCustomer(customerName);

        try {
            const response = await getPricingAgreementsForCustomer(customerName);
            setSelectedCustomerData(response.data);
            setSelectedCustomerPricingAgreements(response.data.PricingAgreements);
        } catch (error) {
            console.error('Error fetching pricing agreements:', error);
            setSelectedCustomerData(null);
            setSelectedCustomerPricingAgreements([]);
        }
    };

    const handleProductSelect =  (product) => {
        if (product) {
            setSelectedProduct(product);

            // Find pricing information for the selected product
            const selectedProductPricing = selectedCustomerPricingAgreements.find(item => item.ProductID === product.ProductID);
            setSelectedProductPricing(selectedProductPricing);
        } else {
            // Handle the case when no productis selected, e.g. clear the selectedProduct state
            setSelectedProduct(null);
            setSelectedProductPricing(null);
        }

    };

    const handleAddSale = () => {
        // Ensure the required fields are filled
        if (!selectedCustomer || !selectedProduct || !saleDate || !saleQuantity || !invoiceNumber) {
            alert("Please fill in all required fields.");
            return;
        }

        // Create a new sale object and add it to the sales array
        const newSale = {
            ProductID: selectedProduct.ProductID,
            CustomerName: selectedCustomer,
            SaleDate: saleDate,
            SaleQuantity: saleQuantity,
            UnitPrice: selectedProduct.Price,
            InvoiceNumber: invoiceNumber,
        };

        setSales([...sales, newSale]);

        //Clear the input fields after adding a sale
        setSaleDate('');
        setSaleQuantity('');
        setInvoiceNumber('');
    };

    const handleRegisterSales = async () => {
        // Register each sale in the sales array
        try {
            for (const sale of sales) {
                await addSale(sale);
            }

            //Clear the sales array after successful registration
            setSales([]);
        } catch (error) {
            console.error('Error registering sales:', error);
        }
    }


    return(
        <div>
            <div>
                <form>
                    <label>
                        Select Customer:
                        <select
                        value={selectedCustomer}
                        onChange={e => handleCustomerSelect(e.target.value)}
                        >
                            <option value="">Select a customer</option>
                            {customers?.map(customer => (
                                <option
                                key={customer.CustomerName}
                                value={customer.CustomerName}
                                >
                                    {customer.CustomerName}
                                </option>
                            ))}
                        </select>
                    </label>
                </form>
            </div>
            {selectedCustomerData && (
                <div>
                    <h3>Selected Customer: {selectedCustomerData.CustomerName}</h3>
                    <h4>Register Sale:</h4>
                    <form>
                        <div>
                            <label>
                                Select Product:
                                <select
                                    value={selectedProduct ? selectedProduct.ProductID : ''}
                                    onChange={e => {
                                        const selectedProductID = parseInt(e.target.value);
                                        const selectedProduct = selectedCustomerPricingAgreements.find(product => product.ProductID === selectedProductID);
                                        handleProductSelect(selectedProduct)
                                    }}
                                >
                                    <option value="">Select a product</option>
                                    {selectedCustomerPricingAgreements?.map(product => (
                                        <option
                                            key={product.ProductID}
                                            value={product.ProductID}
                                        >
                                            {product.ProductName}
                                        </option>
                                    ))}
                                </select>
                            </label>
                        </div>
                        <div>
                            <label>
                                Sale Date:
                                <input
                                    type="date"
                                    value={saleDate}
                                    onChange={e => setSaleDate(e.target.value)}
                                    required
                                />
                            </label>
                        </div>
                        <div>
                            <label>
                                Sale Quantity:
                                <input
                                    type="number"
                                    value={saleQuantity}
                                    onChange={e => setSaleQuantity(e.target.value)}
                                    required
                                />
                            </label>
                        </div>
                        <div>
                            <label>
                                Invoice Number:
                                <input
                                    type="text"
                                    value={invoiceNumber}
                                    onChange={e => setInvoiceNumber(e.target.value)}
                                    required
                                />
                            </label>
                        </div>
                        <button type="button" onClick={handleAddSale}>Add Sale</button>
                    </form>
                    <div>
                        {selectedProduct && (
                            <div>
                                <h4>Selected Product Pricing:</h4>
                                <p>Product Name: {selectedProductPricing.ProductName}</p>
                                <p>Price: {selectedProductPricing.Price}</p>
                            </div>
                        )}
                    </div>
                    <div>
                        {sales.length > 0 && (
                            <div>
                                <h4>Sales to register:</h4>
                                <ul>
                                    {sales?.map((sale, index) => (
                                        <li key={index}>
                                            Product: {sale.ProductID}, Date: {sale.SaleDate}, Quantity: {sale.SaleQuantity}
                                        </li>
                                    ))}
                                </ul>
                                <button onClick={handleRegisterSales}>Register Sales</button>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}

export default SalesMutations