import{ apiService, apiServiceAWS } from './apiService';

export const getCustomerMetrics = () => {
    return apiServiceAWS.get('/crm/customerdata');
};

export const getPricingAgreementsForCustomer = (customerName) => {
    return apiServiceAWS.get(`/crm/pricingagreementforcustomer/${customerName}`);
};

export const addPricingAgreement = (data) => {
    return apiServiceAWS.post('/crm/addpricingagreement', data);
};

// not in use
export const addTransportationCosts = (customerName, data) => {
    return apiService.post(`/crm/customerdata/transportationcosts/${customerName}`, data);
};

// not in use
export const addSafetyCosts = (customerName, data) => {
    return apiService.post(`/crm/customerdata/safetycosts/${customerName}`, data);
};

export const addCustomer = (data) => {
    return apiServiceAWS.post('/crm/addcustomer', data);
};

export const resetPassword = (data) => {
    return apiServiceAWS.post('/crm/resetpassword', data);
};

export const addDeliveryAddress = (data) => {
    return apiServiceAWS.post('/crm/add-delivery-address', data)
};

export const updateDeliveryAddress = (addressID, data) => {
    return apiServiceAWS.put(`/crm/update-delivery-address/${addressID}`, data)
};

export const updateIsActiveDeliveryAddress = (addressID, data) => {
    return apiServiceAWS.put(`/crm/update-delivery-address-status/${addressID}`, data)
};

export const updatePricingAgreement = (customerName, productID, data) => {
    return apiServiceAWS.put(`/crm/updatepricingagreement/${customerName}/${productID}`, data);
};

export const updateCustomerInvoiceMandatory = (customerName, data) => {
    return apiServiceAWS.put(`/crm/updatecustomerinvoicemandatory/${customerName}`, data);
};

export const deleteAddress = (addressID) => {
    return apiService.delete(`/crm/customerdata/delete-delivery-address/${addressID}`);
};