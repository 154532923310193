import{ apiService, apiServiceAWS } from './apiService';

export const addSale = (data) => {
    return apiService.post('/sales/add', data);
};

// not in use
export const submitRecurringOrder = (data) => {
    return apiService.post('/sales/submitrecurringorder', data);
};

export const getSales = () => {
    return apiServiceAWS.get('/salesdashboard/getsales');
};

export const getNewOrders = () => {
    return apiServiceAWS.get('/orders/getneworders');
};

export const updateActualDeliveryDate = (orderID, data) => {
    return apiServiceAWS.put(`/orders/setactualdeliverydate/${orderID}`, data);
};

export const confirmOrder = (orderID) => {
    return apiServiceAWS.put(`/orders/confirmorder/${orderID}`);
};

export const cancelOrder = (orderID) => {
    return apiServiceAWS.put(`/orders/cancelorder/${orderID}`);
};

export const submitSale = (data) => {
    return apiServiceAWS.post('orders/submitsale', data);
};

export const searchOrders = (params) => {
    return apiServiceAWS.get('/orders/searchorders', {params});
};

export const submitOrder = (data) => {
    return apiServiceAWS.post('/salesportal/submitorder', data);
};

export const getLastInvoice = () => {
    return apiServiceAWS.get('/salesportal/getlastinvoice');
};